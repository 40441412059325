import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/announcing-daemon-support",
  "date": "19th June 2024",
  "title": "Announcing Daemon Support",
  "summary": "Announcing Skpr's new feature for long running auxiliary tasks.",
  "author": "Parth Gohil",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "announcement"
  }, {
    "name": "daemon"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The introduction of Daemon Support in release v0.28.0 is a game-changer. It unlocks the ability to execute long-running auxiliary tasks alongside the main application, be it for security, monitoring, extending existing features, or optimising performance by decoupling specific functionality.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/graphic-terminal-daemon-workflow.gif",
        "alt": "Animated image showing SPX web console"
      }}></img></p>
    <h2>{`The technical benefits`}</h2>
    <h3>{`Supporting sophisticated architectures`}</h3>
    <p>{`Mature applications have sophisticated requirements, and this is where Daemon can help. Read more about the `}<a parentName="p" {...{
        "href": "https://www.previousnext.com.au/blog/symfony-messenger/post-1-introducing-symfony-messenger"
      }}>{`Symfony Messenger integration`}</a>{`
feature that encouraged us to work on this for Skpr. `}</p>
    <p>{`Apart from Symphony Messenger, there are other use cases, such as:`}</p>
    <ul>
      <li parentName="ul">{`Monitoring tools - Long running probe task`}</li>
      <li parentName="ul">{`Security tools - Continuously scan files`}</li>
    </ul>
    <h3>{`Simple to configure`}</h3>
    <p>{`The Skpr project config file makes daemons easy to configure. If you need to pause or suspend them, the Skpr CLI is your best friend.`}</p>
    <h2>{`Documentation`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/daemon/list/"
        }}>{`List Daemons`}</a>{`: This command will help you list the daemons configured on your environment.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/daemon/suspend/"
        }}>{`Suspend Daemons`}</a>{`: Suspend command will help you temporarily suspend a daemon without updating it in the config file.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/using-skpr/daemon/resume/"
        }}>{`Resume Daemons`}</a>{`: Resume command for daemon will resume all daemons configured on a specific environment. `}</li>
    </ul>
    <p>{`We are excited about the possibilities that Daemon support brings to Skpr and look forward to seeing how it empowers developers to build even more powerful and scalable applications.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      